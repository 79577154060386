import React, { useState } from 'react';
import Loader from '../../components/Loader/Loader';
import './Exercises.scss';
import useGetExercises from '../../api/exercises/getExercises';
import exercisesData from '../../data/exercises';
import TableView from '../../components/TableView';
import TopFilters from '../../components/TopFilters';
import { useNavigate } from 'react-router-dom';
import { ItemsSelectionProvider } from '../../contexts/ItemsSelectionContext';
import DeleteExercisesModal from './DeleteExercisesModal';

/**
 * @todo Handle search exercises/query.
 * @example
 * const handleSearch = async (search: string) => {
        console.log({ search });
        if (search.length > 2) {
            const query = `?search=${search}`;
            await getExercises(1, query);
        }
    }
 */
const Exercises = () => {
    const navigate = useNavigate();

    const [deleteModal, setDeleteModal] = useState<boolean>(false);

    const exercisesQuery = useGetExercises();

    const newExercise = () => {
        navigate('/ejercicios/nuevo-ejercicio');
    }

    const openDeleteModal = () => setDeleteModal(true);

    const handleSearch = async (search: string) => {
        console.log({ search });
    }

    if (exercisesQuery.isLoading) return <Loader />;

    const items = exercisesQuery.data?.items || [];
    const totalPages = exercisesQuery.data?.totalPages || 0;

    return (
        <ItemsSelectionProvider>
            <div className='Exercises'>
                <TopFilters onCreate={newExercise} onSearch={handleSearch} onStartDeletion={openDeleteModal} createLabel='Crear ejercicio' />

                <TableView resource={exercisesData} items={items} totalPages={totalPages} />

                {deleteModal && (
                    <DeleteExercisesModal onClose={() => setDeleteModal(false)} />
                )}
            </div>
        </ItemsSelectionProvider>
    );
};

export default Exercises;