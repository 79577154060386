import usePagination from "../../hooks/pagination";
import { ItemBase } from "../../models/v2/base";
import { Resource } from "../../models/v2/resources";
import Pagination from "../Pagination/Pagination";
import Table from "../Table";

export type TableViewProps<T extends ItemBase> = {
    resource: Resource<T>;
    items: T[];
    totalPages: number;
};

const TableView = <T extends ItemBase>({ resource, items, totalPages }: TableViewProps<T>) => {
    const { headers, fields } = resource;

    const { currentPage, goToPage, nextPage, prevPage } = usePagination({ totalPages });

    return (
        <div className="content">
            <Table fields={fields} headers={headers} data={items} />

            <div className="pagination-container">
                <Pagination
                    totalPages={totalPages}
                    current={currentPage}
                    specificPage={goToPage}
                    prev={prevPage}
                    next={nextPage}
                />
            </div>
        </div>
    );
}

export default TableView;
