import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import ExerciseService from "./exercisesService";
import type { Exercise } from "../../models/v2/exercise";

const useUpdateExercise = (exerciseId: Exercise["_id"]) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const exerciseService = new ExerciseService();

  const updateExerciseMutation = useMutation<
    Exercise,
    unknown,
    Partial<Exercise>
  >({
    mutationFn: (payload) =>
      exerciseService.updateExercise(exerciseId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries(["exercise", { id: exerciseId }]);

      navigate("/ejercicios");
    },
  });

  return updateExerciseMutation;
};

export default useUpdateExercise;
