import React from 'react';
import { apiUrl } from '../../data';
import { IMedia } from '../../models/exercise';
import Hexagon from '../Hexagon/Hexagon';
import Trash from '../Icons/Trash';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import './MediaPreview.scss';

type MediaPreviewProps = {
    data: IMedia | File;
    removeMedia?: () => void;
};

const MediaPreview = ({ data, removeMedia = () => null }: MediaPreviewProps) => {
    if (data instanceof File) {
        const isVideo = data.type.indexOf('video') >= 0;

        if (isVideo) {
            return <div className='MediaPreview'>
                <p className="file-name">{data.name || 'Archivo sin nombre'}</p>

                <div className="remove-icon">
                    <Hexagon color='red' onClick={() => removeMedia()}>
                        <Trash />
                    </Hexagon>
                </div>
            </div>
        }

        const url = URL.createObjectURL(data);

        return (
            <div className='MediaPreview'>
                <p className="file-name">{data.name || 'Archivo sin nombre'}</p>

                <div className="img-preview-container">
                    <img src={url} alt="" />
                </div>

                <div className="remove-icon">
                    <Hexagon color='red' onClick={() => removeMedia()}>
                        <Trash />
                    </Hexagon>
                </div>
            </div>

        );
    };

    const mediaType = (type: string) => {
        let x;
        if (type.indexOf('video') >= 0) x = <VideoPlayer data={data} />;
        else if (type.indexOf('image') >= 0) x = (
            <div className="img-preview-container">
                <img src={data.url ? apiUrl + data.url : data.tempUrl} alt="" />
            </div>
        );
        else x = <p>No podemos detectar el archivo</p>;
        return x;
    }

    return (
        <div className='MediaPreview'>
            <p className="file-name">{data.name || 'Archivo sin nombre'}</p>
            {mediaType(data.mimetype)}
            <div className="remove-icon">
                <Hexagon color='red' onClick={() => removeMedia()}>
                    <Trash />
                </Hexagon>
            </div>
        </div>
    );
};

export default MediaPreview;