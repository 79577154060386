import useCreateExercise from "../../api/exercises/createExercise";
import { type CreationExercise } from "../../models/v2/exercise";
import { emptyExercise } from "../../utils/exercises";
import ExerciseForm from "./ExerciseForm";

const NewExercise = () => {
    const createExerciseMutation = useCreateExercise();

    const onCreate = (NewExercise: CreationExercise) => {
        createExerciseMutation.mutate(NewExercise);
    }

    return (
        <div>
            <ExerciseForm exercise={emptyExercise} onSubmit={onCreate} isPending={createExerciseMutation.isLoading} />
        </div>
    )
}

export default NewExercise;