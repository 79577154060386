import React from 'react';
import { colorToHex } from '../../data';

const ArrowRight = ({ color = 'white', width = 15 }) => {
    return (
        <svg width={width} height={width * (width/27)} viewBox="0 0 15 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.64559 0L0 1.64559L11.7522 13.3933L0 25.1409L1.64559 26.7865L14.2161 14.2161L15 13.3933L14.2149 12.5705L1.64445 0H1.64559Z" fill={colorToHex(color)} />
        </svg>
    );
};

export default ArrowRight;