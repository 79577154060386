import { IEquipment, IExercise } from "./models";
import { get, post, put } from "./api/httpRequests";

import { BodyArea } from "./models/v2/exercise";

export const apiUrl = process.env.REACT_APP_API_URL;

export const mapboxApi = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';

// API Calls
/**
 * @description Just tests if the API is in the air.
 */
export const testApi = () => get(`${apiUrl}test`);

// Auth
export const login = (username: string, password: string) => post(`${apiUrl}auth/login-panel`, { username, password });
export const sendVerificationMailToUser = (user: {}) => post(`${apiUrl}auth/send-verification`, user);

// Users
export const getAllUsers = (page = 1, query = '') => get(`${apiUrl}users/all-paged/${page}${query}`);
export const getTrainerApplyments = (page = 1) => get(`${apiUrl}users/trainer-requests/${page}`);
export const getSingleUser = (userId: string) => get(`${apiUrl}users/${userId}`);
export const validateTrainerApplication = (trainerId: string) => put(`${apiUrl}users/validate-trainer/${trainerId}`);
export const rejectApplication = (userId: string) => put(`${apiUrl}users/reject-trainer/${userId}`);
/**
 * 
 * @param firebaseId User firebaseId.
 * @param data Body with the notification data.
 * @returns Notification sent to the user.
 */
export const sendNotification = (firebaseId: string, data: any) => post(`${apiUrl}users/send-notification/${firebaseId}`, data);

// Exercises
export const getAllExercises = (page = 1, search = '') => get(`${apiUrl}exercises/paged/${page}${search}`);
export const getSingleExercise = (id: string) => get(`${apiUrl}exercises/${id}`);
export const updateExercise = (exerciseId: string, data: IExercise, files?: any[]) => put(`${apiUrl}exercises/from-panel/${exerciseId}`, data, files);
export const createNewExercise = (exercise: IExercise | {}, files?: any[]) => post(`${apiUrl}exercises/from-panel`, exercise, files);
export const deleteManyExercises = (data: string[]) => put(`${apiUrl}exercises/delete-many`, { data });
export const testVideoCompression = (video: any) => post(`${apiUrl}exercises/test-compression`, { video }, video);

// Equipment
export const getAllEquipments = () => get(`${apiUrl}equipments`);
export const getPagedEquipment = (page = 1) => get(`${apiUrl}equipments/paged/${page}`);
export const createEquipment = (equipment: IEquipment, files?: any[]) => post(`${apiUrl}equipments`, equipment, files);
export const getSingleEquipment = (id: string) => get(`${apiUrl}equipments/${id}`);
export const updateEquipment = (id: string, equipment: IEquipment, files?: any[]) => put(`${apiUrl}equipments/${id}`, equipment, files);
export const deleteManyEquipments = (data: string[]) => put(`${apiUrl}equipments/delete-many`, { data });

// Admin users functions
export const createAdminUserForPanel = (formData: {}) => post(`${apiUrl}admins`, formData);

// Helper functions
export const fileFormat = (name: string, data: any) => {
  return {
    [name]: data
  }
}

export const getLocationName = async (longitude: number, latitude: number) => {
  try {
    const result = await get(`${mapboxApi}${longitude},${latitude}.json?language=es&types=locality&access_token=${process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}`);
    return result.features;
  } catch (err) {
    console.error('Error getting city name: ', err);
  }
}

export const colorToHex = (color: string) => {
    let hex;
    if      (color === 'dark-blue')    hex = '#262F38';
    else if (color === 'mid-blue')     hex = '#323C46';
    else if (color === 'light-blue')   hex = '#5ACAE2';
    else if (color === 'green')        hex = '#B7E25A';
    else if (color === 'red')          hex = '#FF7171';
    else if (color === 'orange')       hex = '#FFCA41';
    else if (color === 'gray')         hex = '#CACACA';
    else if (color === 'black')        hex = '#000000';
    else if (color === 'white')        hex = '#ffffff';
    // Gradients below
    else if (color === 'light-deg-green') hex = '#B6E25A';
    else if (color === 'dark-deg-green')  hex = '#7EE25A';
    else if (color === 'light-deg-blue')  hex = '#5AE2DA';
    else if (color === 'dark-deg-blue')   hex = '#5ACAE2';

    return hex;
}

/**
 * Transforms a raw string into a url formatted string.
 * @param str {String} String to format as a URL format.
 * @returns {String} URL formatted string.
 */
export const slugify = (str: string): string => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
      str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes

  return str;
};

// Constants
export const muscles: {
  name: string;
  pic: string;
  area: BodyArea;
}[] = [ // Area can be 'top', 'middle' or 'bottom'
  // Tren superior - below
  {
    name: 'Bíceps',
    pic: 'https://images.unsplash.com/photo-1583454122781-8cf8f5af9d2b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
    area: BodyArea.TOP,
  },
  {
    name: 'Tríceps',
    pic: 'https://images.unsplash.com/photo-1530822847156-5df684ec5ee1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
    area: BodyArea.TOP,
  },
  {
    name: 'Antebrazo',
    pic: 'https://images.unsplash.com/photo-1588357937997-2b82e303209e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
    area: BodyArea.TOP,
  },
  {
    name: 'Deltoides',
    pic: 'https://images.unsplash.com/photo-1590556409300-431697bafd4f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
    area: BodyArea.TOP,
  },
  {
    name: 'Manguito rotador externo',
    pic: 'https://images.unsplash.com/photo-1552820755-733e038f86d5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
    area: BodyArea.TOP,
  },
  {
    name: 'Manguito rotador interno',
    pic: 'https://images.unsplash.com/photo-1514672013381-c6d0df1c8b18?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60',
    area: BodyArea.TOP,
  },
  {
    name: 'Pectorales',
    pic: 'https://images.unsplash.com/photo-1582503270192-f0970b2fef75?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80',
    area: BodyArea.TOP,
  },
  {
    name: 'Dorsal',
    pic: 'https://images.unsplash.com/photo-1434682881908-b43d0467b798?ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80',
    area: BodyArea.TOP,
  },
  {
    name: 'Paravertebral',
    pic: 'https://images.unsplash.com/photo-1530954979821-95eba64037a4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
    area: BodyArea.TOP,
  },
  {
    name: 'Trapecio',
    pic: 'https://images.unsplash.com/photo-1589132332846-c6e34f064e06?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1052&q=80',
    area: BodyArea.TOP,
  },
  // Estabilizadores del tronco - below
  {
    name: 'Abdominales',
    pic: 'https://images.unsplash.com/photo-1577221084712-45b0445d2b00?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=643&q=80',
    area: BodyArea.MIDDLE,
  },
  {
    name: 'Lumbares',
    pic: 'https://images.unsplash.com/photo-1568341954308-b165b3096a25?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=564&q=80',
    area: BodyArea.MIDDLE,
  },
  {
    name: 'Oblícuo',
    pic: 'https://images.unsplash.com/photo-1437935690510-49ce2c715aee?ixlib=rb-1.2.1&auto=format&fit=crop&w=1189&q=80',
    area: BodyArea.MIDDLE,
  },
  // Tren inferior - below
  {
    name: 'Aductores',
    pic: 'https://images.unsplash.com/photo-1534367990512-edbdca781b00?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80',
    area: BodyArea.BOTTOM,
  },
  {
    name: 'Cuádriceps',
    pic: 'https://images.unsplash.com/photo-1470468969717-61d5d54fd036?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=683&q=80',
    area: BodyArea.BOTTOM,
  },
  {
    name: 'Isquiotibial',
    pic: 'https://images.unsplash.com/photo-1532383192429-7f602da670a8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
    area: BodyArea.BOTTOM,
  },
  {
    name: 'Gemelos',
    pic: 'https://images.unsplash.com/photo-1581187375550-48c9a182e38b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=926&q=80',
    area: BodyArea.BOTTOM,
  },
  {
    name: 'Glúteos',
    pic: 'https://images.unsplash.com/photo-1522991260885-a32a5bc6da38?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
    area: BodyArea.BOTTOM,
  },
  {
    name: 'Psoas',
    pic: 'https://images.unsplash.com/photo-1520787497953-1985ca467702?ixlib=rb-1.2.1&auto=format&fit=crop&w=950&q=80',
    area: BodyArea.BOTTOM,
  },
];
