import { Link } from "react-router-dom";
import type { ItemBase } from "../../models/v2/base";
import { isAdmin } from "../../utils/isAdmin";
import { parseItems, type Fields } from "../../utils/tables/fields";
import Checkbox from "../Checkbox/Checkbox";
import EditIcon from "../Icons/EditIcon";
import { useItemsSelection } from "../../contexts/ItemsSelectionContext";
import Thumbnail from "../Thumbnail";

export type TableProps<T extends ItemBase> = {
    fields: Fields<T>;
    headers: (keyof Fields<T>)[];
    data: T[];
};

const Table = <T extends ItemBase>({ fields, headers, data }: TableProps<T>) => {
    const parsedItems = parseItems(data, fields);

    const { selected, toggleItem, setList } = useItemsSelection()

    const toggleAll = () => {
        if (selected.length === parsedItems.length) {
            setList([]);
        } else {
            const ids = data.map((item) => item._id);

            setList(ids)
        }
    }

    return (
        <table className="content-table">
            <thead>
                <tr>
                    {isAdmin() && (
                        <td>
                            <Checkbox onChange={toggleAll} checked={selected.length === parsedItems.length} />
                        </td>
                    )}
                    {headers.map((header) => (
                        <td key={`header_${header}`}>
                            <p>{fields[header].label}</p>
                        </td>
                    ))}
                </tr>
            </thead>

            <tbody>
                {parsedItems.map((item) => (
                    <tr key={`item_${item._id ?? Math.random().toString()}`}>
                        {isAdmin() && (
                            <td>
                                <Checkbox checked={selected.includes(item._id || '')} onChange={() => toggleItem(item._id!)} />
                            </td>
                        )}
                        {headers.map((header) => {
                            const value = item[header];

                            const content = typeof value === 'object' && value?.type === 'image' ? (
                                <Thumbnail src={value.url} />
                            ) : <p>{value as string | number | null}</p>;

                            return (
                                <td key={`item_${header}`}>
                                    {content}
                                </td>
                            )
                        })}

                        <td>
                            <Link to={`${item._id}`}>
                                <EditIcon color="light-blue" />
                            </Link>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default Table;