import { useState } from 'react';
import HexagStar from '../HexagStar/HexagStar';
import { Difficulty } from '../../models/v2/exercise';
import './DifficultySelector.scss';

type DifficultySelectorProps = {
    hardness: Difficulty;
    onChange: (difficulty: Difficulty) => void;
};

const DifficultySelector = ({ hardness, onChange }: DifficultySelectorProps) => {
    const [difficulty, setDifficulty] = useState<string>(hardness);

    const onSelect = (value: Difficulty) => {
        setDifficulty(value);
        onChange(value);
    }

    return (
        <div className='DifficultySelector'>
            <div
                className={`level ${difficulty === Difficulty.BEGINER ? 'selected' : ''}`}
                onClick={() => onSelect(Difficulty.BEGINER)}
            >
                <HexagStar level={1} />
                <p>Iniciado</p>
            </div>
            <div
                className={`level ${difficulty === Difficulty.MEDIUM ? 'selected' : ''}`}
                onClick={() => onSelect(Difficulty.MEDIUM)}
            >
                <HexagStar level={2} />
                <p>Amateur</p>
            </div>
            <div
                className={`level ${difficulty === Difficulty.ADVANCED ? 'selected' : ''}`}
                onClick={() => onSelect(Difficulty.ADVANCED)}
            >
                <HexagStar level={3} />
                <p>Avanzado</p>
            </div>  
        </div>
    );
};

export default DifficultySelector;