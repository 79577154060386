import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar/Sidebar";
import './Root.scss';

const Root = () => {
    return (
        <div className="root">
            <Sidebar />
            <Outlet />
        </div>
    );
}

export default Root;
