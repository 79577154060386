import type { CreationExercise, Exercise } from "../../models/v2/exercise";
import type { PaginatedResponse, PaginationParams } from "../../models/v2/apiPagination";

import Api from "../apiService";
import { QueryParams } from "../../models/v2/api";

class ExercisesService {
  private api: Api;

  constructor() {
    this.api = new Api("/exercises");
  }

  getExercises = async (page = 1): Promise<PaginatedResponse<Exercise>> => {
    try {
      const pagination: PaginationParams = {
        page,
      };

      const res = await this.api.get<PaginatedResponse<Exercise>, QueryParams>("/paged", {
        pagination,
      });

      return res;
    } catch (error) {
      console.error("Error while trying to get exercises: ", error);
      throw error;
    }
  };

  getSingleExercise = async (id: string): Promise<Exercise> => {
    try {
      const res = await this.api.get<Exercise>(`/${id}`);

      return res;
    } catch (error) {
      console.error("Error while trying to get single exercise: ", error);
      throw error;
    }
  };

  createExercise = async (exercise: CreationExercise): Promise<Exercise> => {
    try {
      const res = await this.api.post<CreationExercise>("/", exercise);

      return res;
    } catch (error) {
      console.error("Error while trying to create exercise: ", error);
      throw error;
    }
  };

  updateExercise = async (id: string, exercise: Partial<Exercise>): Promise<Exercise> => {
    try {
      const res = await this.api.put<Partial<Exercise>>(`/${id}`, exercise);

      return res;
    } catch (error) {
      console.error("Error while trying to update exercise: ", error);
      throw error;
    }
  }

  deleteManyExercises = async (ids: string[]): Promise<void> => {
    try {
      await this.api.post(`/delete-many`, { ids });
    } catch (error) {
      console.error("Error while trying to delete many exercises: ", error);
      throw error;
    }
  };
}

export default ExercisesService;
