import type { LoginPayload } from "../../models/auth";
import Api from "../apiService";

class AuthService {
    private api: Api;

    constructor() {
        this.api = new Api('/auth');
    }

    login = async (credentials: LoginPayload) => {
        try {
            const res = await this.api.post('/login', credentials);

            return res;
        } catch (error) {
            console.error('Error while trying to login: ', error);
            throw error;
        }
    }
}

export default AuthService;