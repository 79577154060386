import { apiUrl } from "../data";
import { hasFile } from "../utils/files";
import { objectToFormData } from "../utils/objectToFormData";
import { stringifyParams } from "../utils/qs";

class Api {
  private baseUrl: string;

  constructor(url: `/${string}`) {
    this.baseUrl = `${apiUrl}${url}`;
  }

  get = async <T, K = {}>(path: `/${string}`, params?: K): Promise<T> => {
    const url = new URL(`${this.baseUrl}${path}`);

    if (params) {
      url.search = stringifyParams(params);
    }

    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const resJson = await res.json();

    return resJson.data;
  };

  post = async <T>(path: `/${string}`, payload: T) => {
    const url = `${this.baseUrl}${path}`;

    const hasFiles = hasFile(payload as (T extends object ? T : never));

    const headers: HeadersInit = {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };

    if (!hasFiles) {
      headers["Content-Type"] = "application/json";
    }

    const res = await fetch(url, {
      method: "POST",
      body: hasFiles ? objectToFormData(payload) : JSON.stringify(payload),
      headers,
    });

    const resJson = await res.json();

    return resJson.data;
  };

  put = async <T>(path: `/${string}`, payload: Partial<T>) => {
    const url = `${this.baseUrl}${path}`;

    const hasFiles = hasFile(payload);

    const headers: HeadersInit = {
      Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    };

    if (!hasFiles) {
      headers["Content-Type"] = "application/json";
    }

    const res = await fetch(url, {
      method: "PUT",
      body: hasFiles ? objectToFormData(payload) : JSON.stringify(payload),
      headers,
    });

    const resJson = await res.json();

    return resJson.data;
  };

  delete = async (path: `/${string}`) => {
    const url = `${this.baseUrl}${path}`;

    const res = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });

    const resJson = await res.json();

    return resJson.data;
  };
}

export default Api;
