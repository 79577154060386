import { useQuery } from "react-query";
import ExerciseService from "./exercisesService";
import type { Exercise } from "../../models/v2/exercise";
import type { PaginatedResponse } from "../../models/v2/apiPagination";

const useGetExercises = (page = 1) => {
  const exercisesService = new ExerciseService();

  return useQuery<PaginatedResponse<Exercise>>(["exercises", page], () =>
    exercisesService.getExercises(page)
  );
};

export default useGetExercises;
