import React from 'react';
import { colorToHex } from '../../data';
import './Hexagon.scss';

type Color = 'red' | 'blue' | 'green';

type PrexagonProps = {
    width?: number;
    color: Color;
    transparent?: boolean;
};

type HexagonProps = {
    width?: number;
    children?: any;
    color?: Color;
    onClick?: () => void;
    transparent?: boolean;
};

const Prexagon = ({ width, color, transparent }: PrexagonProps) => (
    <svg width={width} height={width} viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
            <path d="M33.5714 16.8245C34.4554 16.3142 35.5446 16.3142 36.4286 16.8245L50.0259 24.675C50.9099 25.1853 51.4545 26.1286 51.4545 27.1493V42.8502C51.4545 43.8709 50.9099 44.8142 50.0259 45.3245L36.4286 53.175C35.5446 53.6853 34.4554 53.6853 33.5714 53.175L19.9741 45.3245C19.0901 44.8142 18.5455 43.8709 18.5455 42.8502V27.1493C18.5455 26.1286 19.0901 25.1853 19.9741 24.675L33.5714 16.8245Z" fill={transparent ? 'transparent' : 'white'} />
        </g>
        <path d="M32.75 11.8764C34.1423 11.0725 35.8577 11.0725 37.25 11.8764L53.9006 21.4896C55.2929 22.2935 56.1506 23.7791 56.1506 25.3868V44.6132C56.1506 46.2209 55.2929 47.7065 53.9006 48.5104L37.25 58.1236C35.8577 58.9275 34.1423 58.9275 32.75 58.1236L16.0994 48.5104C14.7071 47.7065 13.8494 46.2209 13.8494 44.6133V25.3868C13.8494 23.7791 14.7071 22.2935 16.0994 21.4896L32.75 11.8764Z" stroke={colorToHex(color)} strokeOpacity="0.3" />
        <path d="M32.75 1.87639C34.1423 1.07254 35.8577 1.07254 37.25 1.87639L62.5609 16.4896C63.9532 17.2935 64.8109 18.7791 64.8109 20.3868V49.6133C64.8109 51.2209 63.9532 52.7065 62.5609 53.5104L37.25 68.1236C35.8577 68.9275 34.1423 68.9275 32.75 68.1236L7.43911 53.5104C6.0468 52.7065 5.18911 51.2209 5.18911 49.6132V20.3867C5.18911 18.7791 6.04681 17.2935 7.43911 16.4896L32.75 1.87639Z" stroke={colorToHex(color)} strokeOpacity="0.1" />
        <defs>
            <filter id="filter0_d" x="12.8311" y="13.5845" width="44.3375" height="48.5446" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy="2.85714" />
                <feGaussianBlur stdDeviation="2.85714" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.717647 0 0 0 0 0.886275 0 0 0 0 0.352941 0 0 0 0.15 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </svg>
);

const Hexagon = ({ width = 70, children, color = 'green', onClick = () => null, transparent = false }: HexagonProps) => {
    return (
        <div
            className='Hexagon'
            style={{ width: width + 'px' }}
            onClick={() => onClick()}
        >
            <Prexagon color={color} width={width} transparent={transparent} />
            {children && (
                <div className="content">
                    {children}
                </div>
            )}
        </div>
    );
};

export default Hexagon;