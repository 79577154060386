import { useState } from 'react';
import '../../../../../components/EquipmentFilters/EquipmentFilters.scss';
import { Exercise, ExerciseType } from '../../../../../models/v2/exercise';

export type ExerciseTypeFilterSelectorProps = {
    unique?: boolean;
    onChange: (type: Filter[] | Filter) => void;
    initialValue?: Filter;
};

type Filter = Exercise['type']

const ExerciseTypeFilterSelector = ({ unique, onChange, initialValue = ExerciseType.CARDIO }: ExerciseTypeFilterSelectorProps) => {
    const [filters, setFilters] = useState<Filter[]>([initialValue]);

    const handleFilters = (filter: Filter) => {
        if (unique) {
            setFilters([filter]);

            onChange(filter);
        } else {
            const index = filters.indexOf(filter);
            const provFilters = filters;
            if (index < 0) provFilters.push(filter);
            else provFilters.splice(index, 1);
            setFilters([...provFilters]);

            onChange(provFilters);
        }
    };

    const filtersList = [
        { name: ExerciseType.CARDIO, icon: 'cardio.svg', label: 'Cardio' },
        { name: ExerciseType.STRENGTH, icon: 'strength.svg', label: 'Fuerza' },
        { name: ExerciseType.STRETCHING, icon: 'stretching.svg', label: 'Estiramiento' }
    ];

    return (
        <div className='EquipmentFilters'>
            {filtersList.map(filter => {
                const { name, icon, label } = filter;

                return (
                    <div onClick={() => handleFilters(name)} className={`filter-card ${filters.indexOf(name) >= 0 ? 'selected' : ''}`} key={`exercise-type-filter_new-exercise_${name}`}>
                        <div className="icon-container">
                            <img src={`/images/icons/${icon}`} alt="" />
                        </div>
                        <p className="filter-name">{label}</p>
                    </div>
                )
            })}
        </div>
    );
}

export default ExerciseTypeFilterSelector;
