import type { MediaFile } from "../../models/v2/files";
import Hexagon from "../Hexagon/Hexagon";
import Trash from "../Icons/Trash";
import '../MediaPreview/MediaPreview.scss'

export type PicturePreviewProps = {
    image: MediaFile;
    removeMedia?: () => void;
};

const PicturePreview = ({ image, removeMedia }: PicturePreviewProps) => {
    const { originalName: name, publicUrl } = image;

    return (
        <div className='MediaPreview'>
            <p className="file-name">{name || 'Archivo sin nombre'}</p>
            <div className="img-preview-container">
                <img src={publicUrl} alt={name} />
            </div>
            {removeMedia && (
                <div className="remove-icon">
                    <Hexagon color='red' onClick={removeMedia}>
                        <Trash />
                    </Hexagon>
                </div>
            )}
        </div>
    )
};

export default PicturePreview;
