import React from 'react';
import { colorToHex } from '../../data';

const Funnel = ({ width = 15, color = 'white' }) => {
    return (
        <svg width={width} height={width} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2579 0H1.13148C0.528331 0 0.223984 0.757793 0.651349 1.20032L5.8839 6.61936V12.6562C5.8839 12.8857 5.99201 13.1007 6.17353 13.2323L8.43697 14.8723C8.88351 15.196 9.50539 14.868 9.50539 14.2963V6.61936L14.7381 1.20032C15.1646 0.758672 14.8623 0 14.2579 0Z" fill={colorToHex(color)} />
        </svg>
    );
};

export default Funnel;