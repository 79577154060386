import React from 'react';
import './Modal.scss';

type ModalProps = {
    children: any;
};

const Modal = ({ children }: ModalProps) => {
    return (
        <div className='Modal'>
            <div className="modal-inner">
                {children}
            </div>
        </div>
    );
};

export default Modal;