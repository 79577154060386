import { Fields } from "../utils/tables/fields";

export const genericFields: Fields<any> = {
    _id: {
        label: 'ID'
    },
    createdAt: {
        label: 'Creado en'
    },
    updatedAt: {
        label: 'Actualizado en'
    },
}