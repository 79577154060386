import { PropsWithChildren, createContext, useContext } from "react";
import useArrayState from "../hooks/list";

type ItemsSelectionsContext = {
    selected: string[];
    toggleItem: (itemId: string) => void;
    setList: (items: string[]) => void;
};

export type ItemsSelectionProviderProps = PropsWithChildren;

const Context = createContext<ItemsSelectionsContext>({} as ItemsSelectionsContext);

export const ItemsSelectionProvider = ({ children }: ItemsSelectionProviderProps) => {
    const { list, addItem, removeItem, setList } = useArrayState([] as string[]);

    const onToggle = (itemId: string) => {
        const index = list.indexOf(itemId);

        if (index >= 0) removeItem(index);
        else addItem(itemId);
    }

    return (
        <Context.Provider value={{
            selected: list,
            toggleItem: onToggle,
            setList
        }}>
            {children}
        </Context.Provider>
    );
};

export const useItemsSelection = () => {
    return useContext(Context);
}