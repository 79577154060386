import { Reducer } from "react";
import type { CreationExercise } from "../../../models/v2/exercise";

export type ExerciseFormState = CreationExercise;

type ExerciseFormAction =
  | { type: "SET_NAME_ES"; payload: CreationExercise["nameES"] }
  | { type: "SET_DESCRIPTION"; payload: CreationExercise["description"] }
  | {
      type: "SET_FIELD";
      payload: {
        field: keyof CreationExercise;
        value: CreationExercise[keyof CreationExercise];
      };
    };

const exerciseFormReducer: Reducer<ExerciseFormState, ExerciseFormAction> = (
  state,
  action
) => {
  switch (action.type) {
    case "SET_NAME_ES":
      return { ...state, name: action.payload };
    case "SET_FIELD":
      return {
        ...state,
        [action.payload.field]: action.payload.value,
      };
    default:
      return state;
  }
};

export default exerciseFormReducer;
