import { useState, useEffect } from 'react';
import './App.scss';

import { login } from './data';

import { UserContext } from './contexts/UserContext';
import { RouterProvider } from 'react-router-dom';
import router from './router';

type ToastProps = {
  text: string;
  status?: string;
}

type AppProps = {
  history: {
    replace: (url: string) => void;
    listen: (callback: (location: {
      pathname: string;
      search?: string;
    }, action?: any) => void) => void;
  }
  location: {
    pathname: string;
    state?: any;
    search?: string;
  }
};

function App() {
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const [logged, setLogged] = useState<boolean>(false);
  const [userContext, setUserContext] = useState<UserContext>({});

  const addToast = (toast: ToastProps) => {
    const provToasts = toasts;
    provToasts.push(toast);
    console.log('PUSHED: ', provToasts);
    setToasts([...provToasts]);
    setTimeout(() => {
      provToasts.shift();
      console.log('SHIFTED: ', provToasts);
      setToasts([...provToasts]);
    }, 2000);
  }

  const tryLogin = async (username: string, password: string) => {
    try {
      const result = await login(username, password);
      console.log('Res from login: ', result);
      if (result.error) {
        addToast({
          text: result.error,
          status: 'danger'
        });
      } else {
        localStorage.setItem('jwt', result.token);
        if (result.status) localStorage.setItem('status', result.status);
        if (result.permissions) localStorage.setItem('permissions', result.permissions);
        if (result.id) localStorage.setItem('id', result.id);
        const context = {
          status: result.status,
          permissions: result.permissions,
          id: result.id,
        };
        setUserContext(context);
        setLogged(true);
        // history.replace('/home');
      }
    } catch (err) {
      console.error('Error in login: ', err);
      addToast({
        text: 'Algo fue mal',
        status: 'danger',
      })
    }
  }

  const logout = () => {
    localStorage.clear();
    setLogged(false);
  }



  useEffect(() => {
    const checkLogin = () => {
      const isLogged = localStorage.getItem('jwt');
      const permissions = localStorage.getItem('permissions');
      const status = localStorage.getItem('status');
      const id = localStorage.getItem('id');
      console.log({ status, permissions, id });
      const context = { status, permissions, id };
      setUserContext(context);
      console.log('Is logged: ', isLogged);
      if (!isLogged || isLogged.length < 5) {
        // history.replace('/login');
        setLogged(false);
      } else {
        setLogged(true);
      }
    }

    // history.listen((location) => {
    //   if (location.pathname.indexOf('login') >= 0) logout();
    //   else checkLogin();
    // });
  }, []);

  const params = {
    addToast: (toast: ToastProps) => addToast(toast)
  }

  // const PrivateRoute = ({ render: Component, ...rest }: { render: any, path: string }) => (
  //   <Route {...rest} render={(props: any) => (
  //     userContext.status === 'GOD'
  //       ? <Component {...props} />
  //       : <Redirect to='/login' />
  //   )} />
  // );

  return (
    <RouterProvider router={router} />
    // <UserProvider value={userContext}>
    //   <div className="App">
    //     {logged && (
    //       <Sidebar />
    //     )}
    //     <div className="panel-content">
    //       <Switch>
    //         <CompatRoute path='/login' render={() => <Login />} />
    //         {/* <PrivateRoute path='/ajustes' render={() => <Settings {...params} />} />
    //         <PrivateRoute path='/usuarios/:user_id' render={() => <SingleUser {...params} />} />
    //         <PrivateRoute path='/usuarios' render={() => <UsersManagement />} />
    //         <PrivateRoute path='/solicitudes' render={() => <TrainerApplyments />} />
    //         <Route path='/ejercicios/:exercise_id' render={() => <SingleExercise {...params} />} />
    //         <Route path='/ejercicios' render={() => <Exercises />} />
    //         <Route path='/equipamiento/:equipment_id' render={() => <SingleEquipment {...params} />} />
    //         <Route path='/equipamiento' render={() => <Equipment />} />
    //         <Route path='/home' render={() => <p>Hola que tal</p>} /> */}
    //       </Switch>
    //       <div className="toasts-container">
    //         {toasts?.length > 0 && toasts.map((toast, i) => (
    //           <Toast
    //             text={toast.text}
    //             status={toast.status}
    //             key={toast.status + toast.text + i}
    //           />
    //         ))}
    //       </div>
    //     </div>
    //   </div>
    // </UserProvider>
  );
}

export default App;
