import { useState } from 'react';
import BodyPart from '../../../../components/BodyPart/BodyPart';
import '../../SingleExercise/SingleExercise.scss';
import { BodyArea } from '../../../../models/v2/exercise';

export type BodyPartSelectorProps = {
    onChange: (bodyArea: BodyArea) => void;
    initialValue?: BodyArea;
};

const BodyPartSelector = ({ onChange, initialValue = BodyArea.TOP }: BodyPartSelectorProps) => {
    const [bodyArea, setBodyArea] = useState<BodyArea>(initialValue);

    const onSelect = (value: BodyArea) => {
        setBodyArea(value);
        onChange(value);
    };
    
    return (
        <div className="body-area container">
            <p className="label">Parte del cuerpo</p>
            <BodyPart
                click={() => onSelect(BodyArea.TOP)}
                part={BodyArea.TOP}
                selected={bodyArea.indexOf(BodyArea.TOP) >= 0}
            />
            <BodyPart
                click={() => onSelect(BodyArea.MIDDLE)}
                part={BodyArea.MIDDLE}
                selected={bodyArea.indexOf(BodyArea.MIDDLE) >= 0}
            />
            <BodyPart
                click={() => onSelect(BodyArea.BOTTOM)}
                part={BodyArea.BOTTOM}
                selected={bodyArea.indexOf(BodyArea.BOTTOM) >= 0}
            />
            <BodyPart
                click={() => onSelect(BodyArea.FULL_BODY)}
                part={BodyArea.FULL_BODY}
                selected={bodyArea.indexOf(BodyArea.FULL_BODY) >= 0}
            />
        </div>
    )
}

export default BodyPartSelector;
