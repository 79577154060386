import './Thumbnail.scss';

export type ThumbnailProps = {
    src: string;
    alt?: string;
};

const Thumbnail = ({ src, alt = '' }: ThumbnailProps) => {
    return (
        <div className="thumbnail">
            <img src={src} alt={alt} />
        </div>
    )
};

export default Thumbnail;