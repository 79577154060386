import useDeleteManyExercises from "../../api/exercises/deleteManyExercises";
import GradientButton from "../../components/GradientButton/GradientButton";
import Modal from "../../components/Modal/Modal";
import { useItemsSelection } from "../../contexts/ItemsSelectionContext";

export type DeleteExercisesModalProps = {
    onClose: () => void;
};

const DeleteExercisesModal = ({ onClose }: DeleteExercisesModalProps) => {
    const { selected, setList } = useItemsSelection();

    const deleteExercisesMutation = useDeleteManyExercises();

    const onDelete = async () => {
        await deleteExercisesMutation.mutateAsync(selected);
        setList([]);
        onClose();
    }

    return (
        <Modal>
            <div className="delete-modal">
                <p>¿Estás seguro de que quieres borrar los ejercicios seleccionados? No habrá vuelta atrás.</p>
                <div className="btns-container">
                    <GradientButton color='green' click={onClose}>
                        Cancelar
                    </GradientButton>
                    <GradientButton color='red' click={onDelete}>
                        Borrar
                    </GradientButton>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteExercisesModal;
