export function stringifyParams<T extends Record<string, any>>(params: T) {
  const searchParams = new URLSearchParams();

  const flattenObject = (obj: Record<string, any>, prefix = "") => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const newKey = prefix ? `${prefix}[${key}]` : key;

        if (typeof value === "object" && value !== null) {
          flattenObject(value, newKey);
        } else {
          searchParams.append(newKey, value);
        }
      }
    }
  };

  flattenObject(params);

  return searchParams.toString();
}
