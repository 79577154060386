import React from 'react';
import './Sidebar.scss';
import { NavLink } from 'react-router-dom';
import UsersIcon from '../Icons/Users';
import ArrowRight from '../Icons/ArrowRight';
import ArmWeight from '../Icons/ArmWeight';
import OnOff from '../Icons/OnOff';
import { useLocation, useNavigate } from 'react-router-dom';

type NavLinkRenderProps = {
    isActive: boolean;
    isPending: boolean;
    isTransitioning: boolean;
};

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear();
        navigate('/login');
    }

    if (location.pathname === '/login') return null;

    const menuItems = [
        // {
        //     key: 'usuarios',
        //     Icon: UsersIcon,
        //     label: 'Usuarios',
        // },
        // {
        //     key: 'solicitudes',
        //     Icon: BigWeight,
        //     label: 'Solicitudes de entrenadores',
        // },
        {
            key: 'ejercicios',
            Icon: ArmWeight,
            label: 'Ejercicios',
        },
        // {
        //     key: 'equipamiento',
        //     Icon: Equipment,
        //     label: 'Equipamiento',
        // },
        // {
        //     key: 'quejas',
        //     Icon: Warning,
        //     label: 'Quejas',
        // },
        // {
        //     key: 'ajustes',
        //     Icon: Cog,
        //     label: 'Ajustes',
        // }
    ]

    const getNavLinkClassName = ({ isActive }: NavLinkRenderProps): string => {
        const classNames = ['menu-item-link'];

        if (isActive) {
            classNames.push('selected');
        }

        return classNames.join(' ');
    }

    return (
        <>
            <div className='Sidebar'>
                <header>
                    <div className="logo-container">
                        <div className="img-container">
                            <img src="/images/logos/logo-comove.svg" alt="Logo CoMove" />
                        </div>
                        <p className="title light">CoMove</p>
                    </div>
                </header>

                <ul className="list-menu">
                    {menuItems.map(({ key, Icon, label }) => {
                        const isActive = location.pathname.indexOf(key) >= 0;

                        return (
                            <li className='menu-item' key={`menu-item_${key}`}>
                                <NavLink to={`/${key}`} className={getNavLinkClassName}>
                                    <div className="left-side">
                                        <div className="icon-container">
                                            <Icon color={isActive ? 'green' : 'white'} width={20} />
                                        </div>
                                        <p>{label}</p>
                                    </div>
                                    <div className="arrow-container">
                                        <ArrowRight color={isActive ? 'green' : 'white'} width={20} />
                                    </div>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
                
                <div className="logout" onClick={logout}>
                    <div className="left-side">
                        <div className="icon-container on-off">
                            <OnOff width={20} />
                        </div>
                        <p>Cerrar Sesión</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sidebar;