import React from 'react';
import './ShinyHexagon.scss';

type ShinyProps = {
    width: number;
};

type ShinyHexagonProps = {
    width?: number;
    children?: any;
    onClick?: () => void;
};

const Shiny = ({ width }: ShinyProps) => (
    <svg width={width} height={width * (width/78)} viewBox="0 0 70 78" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
            <path d="M32.75 6.87639C34.1423 6.07254 35.8577 6.07254 37.25 6.87639L61.6949 20.9896C63.0872 21.7935 63.9449 23.2791 63.9449 24.8868V53.1133C63.9449 54.7209 63.0872 56.2065 61.6949 57.0104L37.25 71.1236C35.8577 71.9275 34.1423 71.9275 32.75 71.1236L8.30514 57.0104C6.91283 56.2065 6.05514 54.7209 6.05514 53.1132V24.8868C6.05514 23.2791 6.91283 21.7935 8.30514 20.9896L32.75 6.87639Z" stroke="#B7E25A" />
        </g>
        <defs>
            <filter id="filter0_d" x="0.555115" y="0.773438" width="68.8897" height="76.453" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset />
                <feGaussianBlur stdDeviation="2.5" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.717647 0 0 0 0 0.886275 0 0 0 0 0.352941 0 0 0 0.5 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </svg>

);

const ShinyHexagon = ({ width = 70, children, onClick = () => null }: ShinyHexagonProps) => {
    return (
        <div className='ShinyHexagon' onClick={() => onClick()}>
            <Shiny width={width} />
            {children && (
                <div className="children-container">
                    {children}
                </div>
            )}
        </div>
    );
};

export default ShinyHexagon;