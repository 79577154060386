import { ChangeEvent } from 'react';
import '../../containers/Exercises/SingleExercise/SingleExercise.scss';
import useArrayState from '../../hooks/list';
import MediaPreview from '../MediaPreview/MediaPreview';
import ShinyHexagon from '../ShinyHexagon/ShinyHexagon';

type FileType = 'image' | 'video';

export type FileUploaderProps = {
    multiple?: boolean;
    accept?: FileType[]
    onChange: (files: File[]) => void;
    label?: string;
};

const FileUploader = ({ multiple, accept = ['image'], onChange, label = 'Multimedia' }: FileUploaderProps) => {
    const { list: rawFiles, setList: setRawFiles, removeItem: removeRawFile } = useArrayState<File>([]);

    const onAddFile = (event: ChangeEvent<HTMLInputElement>) => {
        const newFiles = event.target.files || [];

        const updatedFiles = [...rawFiles, ...Array.from(newFiles)];

        setRawFiles(updatedFiles);
        onChange(updatedFiles);
    };

    const showAddButton = () => {
        if (multiple) return true;

        return rawFiles.length === 0;
    }

    return (
        <div className="multimedia container">
            <p className="label">{label}</p>
            <div className="items-container media">
                {rawFiles.map((file, fileIndex) => {
                    const { name, lastModified, size } = file;

                    return (
                        <MediaPreview
                            data={file}
                            key={`${name}_${lastModified}_${size}`}
                            removeMedia={() => removeRawFile(fileIndex)}
                        />
                    );
                })}

                {showAddButton() && (
                    <div className="add-file-container">
                        <ShinyHexagon>
                            <p>+</p>
                            <input
                                type="file"
                                id="add-file"
                                accept={accept.map(type => `${type}/*`).join(',')}
                                multiple={multiple}
                                onChange={onAddFile}
                            />
                        </ShinyHexagon>
                    </div>
                )}
            </div>
        </div>
    )
};

export default FileUploader;
