import { useMutation } from "react-query";
import AuthService from "./authService";
import { useNavigate } from "react-router-dom";

const useLoginMutation = () => {
  const navigate = useNavigate();
  const authService = new AuthService();

  const loginMutation = useMutation({
    mutationFn: authService.login,
    onSuccess: ({ token/* , user */ }) => {
      localStorage.setItem('jwt', token);

      navigate('/ejercicios');
    }
  });

  return loginMutation;
};

export default useLoginMutation;
