import React from 'react';
import './BodyPart.scss';

type Part = 'top' | 'middle' | 'bottom' | 'full-body';

type BodyPartProps = {
    part: Part;
    selected?: boolean;
    click?: () => void;
};

const BodyPart = ({ part, selected, click = () => null }: BodyPartProps) => {
    const text = () => {
        let x;
        switch(part) {
            case 'top':
                x = 'Tren superior';
                break;
            case 'middle':
                x = 'Estabilizadores del tronco';
                break;
            case 'bottom':
                x = 'Tren inferior';
                break;
            case 'full-body':
                x = 'Full Body';
                break;
            default:
                x = 'Algo malo pasó';
                break;
        }
        return x;
    }
    return (
        <div className={`BodyPart ${selected ? 'selected' : ''}`} onClick={() => click()}>
            <div className="img-container">
                <img src={`/images/body-parts/${part}-train.png`} alt=""/>
            </div>
            <p>{text()}</p>
        </div>
    );
};

export default BodyPart;