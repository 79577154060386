import React from 'react';
import './HexagStar.scss';

type HexagStarProps = {
    level?: number;
};

const HexagStar = ({ level = 3 }: HexagStarProps) => {

    const star = () => {
        let img;
        let difficulty;
        switch(level) {
            case 1:
                img = 'triangle';
                difficulty = 'easy';
                break;
            case 2:
                img = 'four-points';
                difficulty = 'medium';
                break;
            case 3:
                img = 'five-points';
                difficulty = 'difficult';
                break;
            default:
                img = 'triangle';
                difficulty = 'easy';
                break;
        }
        return {
            img,
            difficulty,
        };
    }

    return (
        <div className={`HexagStar ${star().difficulty}`}>
            <div className="star-container">
                <img src={`/images/svg/${star().img}.svg`} alt="" />
            </div>
        </div>
    );
};

export default HexagStar;