import { useReducer } from "react";
import InputText from "../../../components/InputText/InputText";
import type { CreationExercise, ExerciseType } from "../../../models/v2/exercise";
import exerciseFormReducer from "./reducer";
import '../SingleExercise/SingleExercise.scss';
import ExerciseTypeFilterSelector from "./components/ExerciseTypeFilterSelector";
import DifficultySelector from "../../../components/DifficultySelector/DifficultySelector";
import BodyPartSelector from "./components/BodyPartSelector";
import MusclesSelector from "./components/MusclesSelector";
import GradientButton from "../../../components/GradientButton/GradientButton";
import { isAdmin } from "../../../utils/isAdmin";
import { useNavigate } from "react-router-dom";
import FileUploader from "../../../components/FileUploader";
import PicturePreview from "../../../components/PicturePreview";
import VideoPreview from "../../../components/VideoPreview";

export type ExerciseFormProps = {
    exercise: CreationExercise;
    onSubmit: (exercise: CreationExercise) => void;
    isPending?: boolean;
};

const ExerciseForm = ({ exercise, onSubmit, isPending }: ExerciseFormProps) => {
    const navigate = useNavigate();

    const goBack = () => navigate('..', { relative: 'path' })

    const [state, dispatch] = useReducer(exerciseFormReducer, exercise);

    const { nameES, description, difficulty, muscles = [], bodyArea, type, picture, images = [], videos = [] } = state;

    const handleInput = <T extends keyof CreationExercise>(field: T) => (value: CreationExercise[T]) => {
        dispatch({ type: 'SET_FIELD', payload: { field, value } });
    };

    const handleSubmit = () => {
        onSubmit(state);
    }

    const renderImage = () => {
        if (!(picture instanceof File) && picture) {
            return <PicturePreview image={picture} removeMedia={() => handleInput('picture')(undefined)} />
        }

        return <FileUploader label="Foto del ejercicio" onChange={files => handleInput('picture')(files[0])} />
    }

    return (
        <div className='SingleExercise'>
            <div className="row">
                <div className="name container">
                    <p className='label'>Nombre</p>
                    <InputText
                        value={nameES}
                        onChange={handleInput('nameES')}
                        placeholder="Ej: Flexiones"
                    />
                </div>
                <div className="description container">
                    <p className="label">Descripción</p>
                    <InputText
                        value={description}
                        onChange={handleInput('description')}
                        textarea
                        placeholder="Ej: Dobla los brazos, besa el suelo y estira los brazos."
                    />
                </div>
            </div>

            <div className="row">
                <div className="container">
                    <p className="label">¿Qué tipo de ejercicio es?</p>
                    <ExerciseTypeFilterSelector unique onChange={value => handleInput('type')(value as ExerciseType)} initialValue={type} />
                </div>

                <div className="difficulty container">
                    <p className="label">Dificultad</p>
                    <DifficultySelector
                        hardness={difficulty}
                        onChange={handleInput('difficulty')}
                    />
                </div>
            </div>

            <div className="row">
                <BodyPartSelector onChange={handleInput('bodyArea')} initialValue={bodyArea} />

                <MusclesSelector initialMuscles={muscles} onChange={handleInput('muscles')} bodyArea={bodyArea ? [bodyArea] : []} />
            </div>

            <div className="row">
                {renderImage()}
            </div>

            <div className="row">
                {images.map((image) => {
                    if (!(image instanceof File) && image) {
                        return <PicturePreview image={image} key={`images_pic_${image.publicUrl}`} />
                    }

                    return null
                })}
                <FileUploader label="Fotos" onChange={handleInput('images')} multiple />
            </div>

            <div className="row">
                {videos.map((video) => {
                    if (video instanceof File) {
                        return null
                    }

                    return <VideoPreview key={video.publicUrl} video={video} />
                })}
                <FileUploader label="Videos" onChange={handleInput('videos')} accept={['video']} multiple />
            </div>

            <div className="btns-container">
                <GradientButton disabled={!!isPending} click={goBack}>Cancelar</GradientButton>
                {isAdmin() && <GradientButton disabled={!!isPending} click={handleSubmit} color="green">Guardar</GradientButton>}

            </div>
        </div>
    );
}

export default ExerciseForm;
