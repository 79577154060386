export function objectToFormData(obj: any, formData?: FormData, parentKey?: string): FormData {
    formData = formData || new FormData();

    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            const formKey = parentKey ? `${parentKey}[${key}]` : key;

            if (typeof value === 'object' && !(value instanceof File)) {
                objectToFormData(value, formData, formKey);
            } else if (Array.isArray(value) && value.every(item => item instanceof File)) {
                value.forEach((item) => {
                    formData.append(formKey, item);
                });
            } else if (Array.isArray(value)) {
                value.forEach((item, index) => {
                    const arrayKey = `${formKey}[${index}]`;
                    objectToFormData(item, formData, arrayKey);
                });
            } else {
                formData.append(formKey, value);
            }
        }
    }

    return formData;
}