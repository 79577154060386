import type { ItemBase } from "../../models/v2/base";
import { get } from "../objects";

export type ImageValue = {
  type: "image";
  url: string;
};

export type FieldValue = string | number | undefined | null | ImageValue;

export type Field<T extends ItemBase> = {
  valuePath?: string;
  getValue?: (item: T) => FieldValue;
  label: string;
};

export type Fields<T extends ItemBase> = {
  [key: string]: Field<T>;
};

export type ParsedItem = Record<string, FieldValue> & { _id?: string };

export type Header<T extends ItemBase> = keyof Fields<T>;

export function getFieldValue<T extends ItemBase>(
  field: Field<T>,
  item: T,
  fieldName: string
): FieldValue {
  if (field.getValue) {
    return field.getValue(item);
  }

  if (field.valuePath) {
    return get(item, field.valuePath);
  }

  const value = item[fieldName as keyof T] as FieldValue;

  return value;
}

export function getFieldsValues<T extends ItemBase>(
  fields: Fields<T>,
  item: T
): ParsedItem {
  const parsedItem: ParsedItem = {};

  Object.keys(fields).forEach((fieldName) => {
    const field = fields[fieldName];

    const value = getFieldValue(field, item, fieldName);

    parsedItem[fieldName] = value;
  });

  return parsedItem;
}

export function parseItems<T extends ItemBase>(
  items: T[],
  fields: Fields<T>
): ParsedItem[] {
  return items.map((item) => getFieldsValues(fields, item));
}
