import './Checkbox.scss';

type CheckboxProps = {
    passData?: (value: boolean) => void;
    onClick?: () => void;
    onChange?: () => void;
    checked?: boolean;
};

const Checkbox = ({ onChange, checked }: CheckboxProps) => {    
    return (
        <input
            onChange={onChange}
            className='Checkbox'
            type="checkbox"
            checked={checked}
        />
    );
};

export default Checkbox;