import Card from '../../../../components/Card/Card';
import { muscles } from '../../../../data';
import { BodyArea, type Exercise } from '../../../../models/v2/exercise';
import '../../SingleExercise/SingleExercise.scss';
import useArrayState from '../../../../hooks/list';
import { useEffect } from 'react';

export type MusclesSelectorProps = {
    bodyArea: BodyArea[]
    initialMuscles?: Exercise['muscles']
    onChange: (muscles: Exercise['muscles']) => void
};

const MusclesSelector = ({ bodyArea, initialMuscles = [], onChange }: MusclesSelectorProps) => {
    const { list: selectedMuscles, addItem, removeItem } = useArrayState<string>(initialMuscles);

    const onSelect = (value: string) => {
        if (selectedMuscles.indexOf(value) >= 0) {
            removeItem(selectedMuscles.indexOf(value));
        } else {
            addItem(value)
        };
    }

    useEffect(() => {
        onChange(selectedMuscles);
    }, [selectedMuscles])

    return (
        <div className="muscles container">
            <p className="label">Músculos</p>
            {bodyArea.length > 0 ? (
                <div className="items-container muscles">
                    {muscles?.filter(m => {
                        if (bodyArea.includes(BodyArea.FULL_BODY)) return m;
                        if (bodyArea.length > 0) return bodyArea.indexOf(m.area) >= 0;
                        return m;
                    }).map((muscle, i) => (
                        <Card
                            key={muscle.name + i}
                            img={muscle.pic}
                            text={muscle.name}
                            selected={selectedMuscles && selectedMuscles.indexOf(muscle.name) >= 0}
                            click={() => onSelect(muscle.name)}
                        />
                    ))}
                </div>
            ) : (
                <p>Selecciona alguna parte del cuerpo (o varias)</p>
            )}
        </div>
    )
}

export default MusclesSelector;
