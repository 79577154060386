import React from 'react';
import './Card.scss';

type CardProps = {
    img: string;
    text: string;
    selected?: boolean;
    click?: () => void;
};

const Card = ({ img, text, selected, click = () => null }: CardProps) => {
    return (
        <div className={`Card ${selected ? 'selected' : ''}`} onClick={() => click()}>
            <div className="img-container">
                <img src={img} alt="" />
            </div>
            <div className="text-container">
                <p className={text.length > 10 ? 'text-small' : 'text-normal'}>{text}</p>
            </div>
            <div className="top-layer">
                <div className="check-container">
                    <img src="/images/icons/check.svg" alt="" />
                </div>
            </div>
        </div>
    );
};

export default Card;