import { useParams } from "react-router-dom";
import ExerciseForm from "./ExerciseForm";
import { CreationExercise } from "../../models/v2/exercise";
import useGetSingleExercise from "../../api/exercises/getSingleExercise";
import Loader from "../../components/Loader/Loader";
import useUpdateExercise from "../../api/exercises/updateExercise";

const UpdateExercise = () => {
    const { exerciseId } = useParams();

    const exerciseQuery = useGetSingleExercise(String(exerciseId));

    const exerciseMutation = useUpdateExercise(String(exerciseId));

    const onUpdate = (exercise: CreationExercise) => {
        exerciseMutation.mutate(exercise);
    };

    if (exerciseQuery.isLoading) {
        return <Loader />;
    }

    if (exerciseQuery.isError || !exerciseQuery.data) {
        return <div>Error while trying to get exercise</div>;
    }

    return (
        <div>
            <ExerciseForm exercise={exerciseQuery.data} onSubmit={onUpdate} isPending={exerciseMutation.isLoading} />
        </div>
    )
};

export default UpdateExercise;
