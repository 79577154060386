import React, { useState } from 'react';
import GradientButton from '../../components/GradientButton/GradientButton';
import EyeOff from '../../components/Icons/EyeOff';
import EyeOn from '../../components/Icons/EyeOn';
import InputText from '../../components/InputText/InputText';
import './Login.scss';
import useLoginMutation from '../../api/auth/login';

const Login = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);

    const loginMutation = useLoginMutation();

    const handleLogin = () => {
        loginMutation.mutate({ email, password });
    }

    return (
        <div className='Login'>
            <div className="field">
                <InputText
                    onChange={(value) => setEmail(value)}
                    placeholder="Nombre de usuario"
                    value={email}
                />
            </div>
            <div className="field">
                <InputText
                    onChange={(value) => setPassword(value)}
                    placeholder="Contraseña"
                    value={password}
                    password={!passwordVisible}
                />
                <div className="toggle-psw" onClick={() => setPasswordVisible(!passwordVisible)}>
                    {passwordVisible ? (
                        <EyeOff width={34} />
                    ) : (
                        <EyeOn width={30} />
                    )}
                </div>
            </div>
            <GradientButton color='green' click={handleLogin} disabled={loginMutation.isLoading}>Iniciar sesión</GradientButton>
        </div>
    );
};

export default Login;