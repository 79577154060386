import React from 'react';
import { colorToHex } from '../../data';

const EyeOn = ({ color = 'green', width = 50 }) => {
    return (
        <svg width={width} height={width * (width  / 30)} viewBox="0 0 50 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M32.4935 14.9752C32.4935 18.9473 29.1325 22.1673 24.9864 22.1673C20.8404 22.1673 17.4794 18.9473 17.4794 14.9752C17.4794 11.003 20.8404 7.783 24.9864 7.783C29.1325 7.78296 32.4935 11.003 32.4935 14.9752ZM25 0C20.7073 0.0190417 16.2578 1.06408 12.0457 3.06488C8.91825 4.61163 5.87042 6.79388 3.22475 9.48742C1.92533 10.8624 0.267958 12.8533 0 14.9775C0.0316667 16.8175 2.00542 19.0887 3.22475 20.4676C5.70571 23.0554 8.67417 25.1767 12.0457 26.8917C15.9736 28.798 20.3213 29.8956 25 29.9566C29.2968 29.9373 33.7453 28.8802 37.9528 26.8917C41.0802 25.345 44.1295 23.1612 46.7752 20.4676C48.0746 19.0927 49.732 17.1017 50 14.9775C49.9683 13.1375 47.9946 10.8662 46.7752 9.48733C44.2943 6.89958 41.3243 4.77987 37.9528 3.06479C34.0268 1.15996 29.6684 0.0699583 25 0ZM24.9968 3.71867C31.5018 3.71867 36.775 8.76008 36.775 14.9791C36.775 21.1981 31.5018 26.2395 24.9968 26.2395C18.4919 26.2395 13.2187 21.1981 13.2187 14.9791C13.2187 8.76008 18.4919 3.71867 24.9968 3.71867Z" fill={colorToHex(color)} />
        </svg>
    );
};

export default EyeOn;