import React from 'react';
import { apiUrl } from '../../data';
import { IMedia } from '../../models/exercise';
import './VideoPlayer.scss';

type VideoPlayerProps = {
    data: IMedia;
};

const VideoPlayer = ({ data }: VideoPlayerProps) => {
    return (
        <video controls className='VideoPlayer'>
            <source src={data.url ? apiUrl + data.url : data.tempUrl} />
        </video>
    );
};

export default VideoPlayer;