import { useMutation, useQueryClient } from "react-query";
import ExerciseService from "./exercisesService";
import { useNavigate } from "react-router-dom";

const useCreateExercise = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const exerciseService = new ExerciseService();

  const createExerciseMutation = useMutation({
    mutationFn: exerciseService.createExercise,
    onSuccess: () => {
      queryClient.invalidateQueries("exercises");

      navigate("/ejercicios");
    },
  });

  return createExerciseMutation;
};

export default useCreateExercise;
