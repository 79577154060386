import React, { useState, useEffect } from 'react';
import ArrowRight from '../Icons/ArrowRight';
import './Pagination.scss';

type PaginationProps = {
    totalPages: number;
    prev: () => void;
    next: () => void;
    specificPage: (page: number) => void;
    current?: number;
};

const Pagination = ({ totalPages, next, prev, specificPage, current }: PaginationProps) => {
    const [pages, setPages] = useState<number[]>([]);

    useEffect(() => {
        let pagesArray = Array.from(Array(totalPages).keys());
        pagesArray = pagesArray.map(t => t + 1);
        setPages([...pagesArray]);
    }, [totalPages]);

    return (
        <div className='Pagination'>
            <div className='Pagination'>
                <div className="pagination-container">
                    <div className="prev paginator" onClick={() => prev()}>
                        <div className="icon-container left">
                            <ArrowRight />
                        </div>
                    </div>
                    <div className="pages">
                        {pages?.map((p, i) => (
                            <div className={`page ${current === p ? 'current' : ''}`}
                                onClick={specificPage ? () => specificPage(p) : () => console.log('Nothing bro')}
                                key={`pagination-page-${i}`}>{p}</div>
                        ))}
                    </div>
                    <div className="next paginator" onClick={() => next()}>
                        <div className="icon-container">
                            <ArrowRight />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pagination;