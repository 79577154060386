import React from 'react';
import { colorToHex } from '../../data';

const Lens = ({ width = 24, color = 'white' }) => {
    return (
        <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.2141 20.526C12.4132 20.5255 14.549 19.7632 16.2814 18.3605L21.7279 24L23.4798 22.186L18.0333 16.5465C19.3887 14.7526 20.1254 12.5407 20.1259 10.263C20.1259 4.60423 15.6792 0 10.2141 0C4.74894 0 0.302246 4.60423 0.302246 10.263C0.302246 15.9217 4.74894 20.526 10.2141 20.526ZM10.2141 2.56575C14.3139 2.56575 17.6479 6.01796 17.6479 10.263C17.6479 14.508 14.3139 17.9602 10.2141 17.9602C6.11429 17.9602 2.7802 14.508 2.7802 10.263C2.7802 6.01796 6.11429 2.56575 10.2141 2.56575Z" fill={colorToHex(color)} />
        </svg>
    );
};

export default Lens;