import { useState } from "react";

type ArrayHook<T> = {
  list: T[];
  addItem: (item: T) => void;
  removeItem: (index: number) => void;
  updateItem: (index: number, newItem: T) => void;
  setList: (list: T[]) => void;
};

function useArrayState<T>(initialArray: T[]): ArrayHook<T> {
  const [array, setArray] = useState<T[]>(initialArray);

  const addItem = (item: T) => {
    setArray((prevArray) => [...prevArray, item]);
  };

  const removeItem = (index: number) => {
    setArray((prevArray) => prevArray.filter((_, i) => i !== index));
  };

  const updateItem = (index: number, newItem: T) => {
    setArray((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = newItem;
      return newArray;
    });
  };

  const setList = (list: T[]) => {
    setArray(list);
  };

  return {
    list: array,
    addItem,
    removeItem,
    updateItem,
    setList,
  };
}

export default useArrayState;
