import { useState } from "react";
import type { MediaFile } from "../../models/v2/files";
import Hexagon from "../Hexagon/Hexagon";
import Trash from "../Icons/Trash";
import '../MediaPreview/MediaPreview.scss'
import EyeOn from "../Icons/EyeOn";
import Modal from "../Modal/Modal";
import GradientButton from "../GradientButton/GradientButton";
import './VideoPreview.scss';

export type VideoPreviewProps = {
    video: MediaFile;
    removeMedia?: () => void;
};

const VideoPreview = ({ video, removeMedia }: VideoPreviewProps) => {
    const { originalName: name, publicUrl } = video;

    const [showModal, setShowModal] = useState(false);

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    return (
        <>
            <div className='MediaPreview'>
                <p className="file-name">{name || 'Archivo sin nombre'}</p>

                <div className="video-buttons-container">
                    <button onClick={openModal}><EyeOn width={30} /></button>

                    {removeMedia && (
                        <div className="remove-icon">
                            <Hexagon color='red' onClick={removeMedia}>
                                <Trash />
                            </Hexagon>
                        </div>
                    )}
                </div>
            </div>
            {showModal && (
                <Modal>
                    <div className="video-preview-container">
                        <video src={publicUrl} controls />
                    </div>

                    <GradientButton click={closeModal}>Cancelar</GradientButton>
                </Modal>
            )}
        </>
    )
};

export default VideoPreview;
