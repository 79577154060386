export function get(obj: any, key: string): any {
    const keys = key.split('.');
    let value = obj;

    for (const k of keys) {
        if (value && typeof value === 'object' && k in value) {
            value = value[k];
        } else {
            return undefined;
        }
    }

    return value;
}