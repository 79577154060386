import React from 'react';
import { colorToHex } from '../../data';

const OnOff = ({ color = 'white', width = 27 }) => {
    return (
        <svg width={width} height={width * (width / 32)} viewBox="0 0 27 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.3333 31.6667C20.685 31.6667 26.6667 25.685 26.6667 18.3334C26.6667 12.75 23.2133 7.96502 18.3333 5.98169V9.68669C19.8515 10.5632 21.1126 11.8235 21.9902 13.3411C22.8678 14.8587 23.331 16.5803 23.3333 18.3334C23.3333 23.8484 18.8483 28.3334 13.3333 28.3334C7.81833 28.3334 3.33333 23.8484 3.33333 18.3334C3.33555 16.5803 3.79867 14.8586 4.67625 13.3409C5.55383 11.8233 6.81502 10.5631 8.33333 9.68669V5.98169C3.45333 7.96502 0 12.75 0 18.3334C0 25.685 5.98167 31.6667 13.3333 31.6667Z" fill={colorToHex(color)} />
            <path d="M11.667 0H15.0003V16.6667H11.667V0Z" fill={colorToHex(color)} />
        </svg>
    );
};

export default OnOff;