import { colorToHex } from "../../data";

export type EditIconProps = {
    width?: number;
    color?: string;
}

const EditIcon = ({ width = 24, color = 'black' }: EditIconProps) => {
    return (
        <svg width={width} height={width} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 24V18.3333L8.8 9.55L17.6 0.766666C17.8667 0.522222 18.1613 0.333333 18.484 0.2C18.8067 0.0666668 19.1453 0 19.5 0C19.8547 0 20.1991 0.0666668 20.5333 0.2C20.8675 0.333333 21.1564 0.533333 21.4 0.8L23.2333 2.66667C23.5 2.91111 23.6947 3.2 23.8173 3.53333C23.94 3.86667 24.0009 4.2 24 4.53333C24 4.88889 23.9391 5.228 23.8173 5.55066C23.6955 5.87333 23.5009 6.16755 23.2333 6.43333L5.66666 24H0ZM19.4667 6.4L21.3333 4.53333L19.4667 2.66667L17.6 4.53333L19.4667 6.4Z" fill={colorToHex(color)} />
        </svg>
    )
}

export default EditIcon;