import { useMutation, useQueryClient } from "react-query";
import ExerciseService from "./exercisesService";

const useDeleteManyExercises = () => {
  const queryClient = useQueryClient();
  const exerciseService = new ExerciseService();

  const deleteManyExercisesMutation = useMutation({
    mutationFn: exerciseService.deleteManyExercises,
    onSuccess: () => {
      queryClient.invalidateQueries("exercises");
    },
  });

  return deleteManyExercisesMutation;
};

export default useDeleteManyExercises;
