import React from 'react';
import './NewButton.scss';

type NewButtonProps = {
    children?: string;
    click?: () => void;
};

const NewButton = ({ children, click = () => null }: NewButtonProps) => {
    return (
        <button className='NewButton' onClick={() => click()}>
            {children || 'Dale'}
            <div className="hexagon-container">
                +
            </div>
        </button>
    );
};

export default NewButton;