import React from 'react';
import { colorToHex } from '../../data';

const Trash = ({ width = 16, color = 'red' }) => {
    return (
        <svg width={width} height={width * (width/18)} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 4.83341H2.16667V15.6667C2.16667 16.1088 2.34226 16.5327 2.65482 16.8453C2.96738 17.1578 3.39131 17.3334 3.83333 17.3334H12.1667C12.6087 17.3334 13.0326 17.1578 13.3452 16.8453C13.6577 16.5327 13.8333 16.1088 13.8333 15.6667V4.83341H3ZM11.8483 2.33341L10.5 0.666748H5.5L4.15167 2.33341H0.5V4.00008H15.5V2.33341H11.8483Z" fill={colorToHex(color)} />
        </svg>
    );
};

export default Trash;