import { useQuery } from "react-query";
import ExerciseService from "./exercisesService";
import type { Exercise } from "../../models/v2/exercise";

const useGetSingleExercise = (exerciseId: string) => {
    const exercisesService = new ExerciseService();

    return useQuery<Exercise>(["exercise", { id: exerciseId }], () =>
        exercisesService.getSingleExercise(exerciseId)
    );
};

export default useGetSingleExercise;