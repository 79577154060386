import React from 'react';
import './GradientButton.scss';

type GradientButtonProps = {
    children?: string;
    color?: string;
    click?: () => void;
    disabled?: boolean;
};

const GradientButton = ({ children = 'Dale', color, click = () => null, disabled }: GradientButtonProps) => {
    return (
        <button disabled={disabled} onClick={() => click()} className={`GradientButton ${color || ''} ${disabled ? 'disabled' : ''}`}>
            {children}
        </button>
    );
};

export default GradientButton;