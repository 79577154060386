import React, { useState } from 'react';
import Funnel from '../Icons/Funnel';
import Lens from '../Icons/Lens';
import './SearchBar.scss';

type SearchBarProps = {
    passData?: (text: string) => void;
}

const SearchBar = ({ passData = () => null }: SearchBarProps) => {
    const [focused, setFocused] = useState<boolean>(false);
    const [text, setText] = useState<string>('');

    const handleChange = (value: string) => {
        setText(value);
        if (value.length > 2) passData(value);
    }

    return (
        <div className={`SearchBar ${focused ? 'focused' : ''}`}>
            <div className="filters-icon">
                <Funnel width={16} />
            </div>
            <input
                type="text"
                placeholder="Busca y encontrarás"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                value={text}
                onChange={(e) => handleChange(e.target.value)}
            />
            <div className="search-icon">
                <Lens width={16} />
            </div>
        </div>
    );
};

export default SearchBar;