import { createBrowserRouter } from "react-router-dom";
import Login from "./containers/Login/Login";
import Exercises from "./containers/Exercises/Exercises";
import Root from "./containers/Root";
import NewExercise from "./containers/Exercises/NewExercise";
import UpdateExercise from "./containers/Exercises/UpdateExercise";

const router = createBrowserRouter([
    {
        path: '/login',
        element: <Login />
    },
    {
        path: '/',
        element: <Root />,
        children: [
            {
                path: '/ejercicios/nuevo-ejercicio',
                element: <NewExercise />
            },
            {
                path: '/ejercicios/:exerciseId',
                element: <UpdateExercise />
            },
            {
                path: '/ejercicios',
                element: <Exercises />
            },
        ]
    },


]);

export default router;