import {
  BodyArea,
  type CreationExercise,
  Difficulty,
  ExerciseType,
} from "../models/v2/exercise";

export const emptyExercise: CreationExercise = {
  nameES: "",
  nameEN: "",
  slugES: "",
  slugEN: "",
  difficulty: Difficulty.BEGINER,
  bodyArea: BodyArea.FULL_BODY,
  muscles: [] as string[],
  movement: true,
  description: "",
  type: ExerciseType.STRENGTH,
  isDraft: true,
};
