import React from 'react';
import { colorToHex } from '../../data';

const EyeOff = ({ color = 'green', width = 50 }) => {
    return (
        <svg width={width} height={width * (width / 43)} viewBox="0 0 50 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.9053 7.64734C26.9515 7.54417 25.9824 7.48505 25 7.47034C20.7073 7.48938 16.2574 8.53355 12.0453 10.5343C8.91788 12.0811 5.87142 14.2647 3.22571 16.9583C1.92633 18.3333 0.267959 20.3242 0 22.4484C0.0316667 24.2884 2.00638 26.5596 3.22571 27.9385C5.70667 30.5263 8.6738 32.6474 12.0453 34.3624C12.1599 34.4181 12.2749 34.473 12.3901 34.5272L9.26213 39.9898L13.5123 42.5017L36.488 2.50034L32.3969 0L27.9053 7.64734V7.64734ZM37.6068 10.3756L34.4849 15.7863C35.9211 17.6523 36.7737 19.9533 36.7737 22.4484C36.7737 28.6674 31.5019 33.7094 24.9969 33.7094C24.7157 33.7094 24.4432 33.679 24.1669 33.6605L22.1008 37.2372C23.0536 37.3392 24.0171 37.4136 25 37.4264C29.2968 37.4071 33.7442 36.3509 37.9517 34.3624C41.0791 32.8157 44.1286 30.6321 46.7743 27.9385C48.0737 26.5636 49.732 24.5726 50 22.4484C49.9683 20.6083 47.9936 18.3371 46.7743 16.9582C44.2933 14.3705 41.3232 12.2493 37.9517 10.5343C37.8379 10.4791 37.7214 10.4295 37.6068 10.3756V10.3756ZM24.997 11.1874C25.2822 11.1874 25.5652 11.1988 25.8454 11.2179L23.4253 15.408C20.0289 16.0966 17.4805 18.9863 17.4805 22.4454C17.4805 23.3143 17.6405 24.1461 17.9352 24.9172C17.9355 24.9181 17.9348 24.9194 17.9352 24.9203L15.509 29.1226C14.0694 27.2549 13.2202 24.9464 13.2202 22.4483C13.2202 16.2293 18.492 11.1874 24.997 11.1874V11.1874ZM32.0374 20.0222L26.5839 29.4705C29.9621 28.7689 32.4921 25.8924 32.4921 22.4454C32.4921 21.5923 32.3219 20.7812 32.0374 20.0222V20.0222Z" fill={colorToHex(color)} />
        </svg>
    );
};

export default EyeOff;