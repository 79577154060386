import type { ItemBase } from "./base";
import type { MediaFile } from "./files";

export enum Difficulty {
  BEGINER = "beginer",
  MEDIUM = "medium",
  ADVANCED = "advanced",
}

export enum BodyArea {
  TOP = "top",
  MIDDLE = "middle",
  BOTTOM = "bottom",
  FULL_BODY = "full-body",
}

export enum ExerciseType {
  CARDIO = "cardio",
  STRETCHING = "stretching",
  STRENGTH = "strength",
}

export type CreationExercise = {
  nameES: string;
  nameEN: string;
  slugES: string;
  slugEN: string;
  difficulty: Difficulty;
  bodyArea?: BodyArea;
  muscles?: string[];
  movement?: boolean;
  objective?: string;
  description?: string;
  otherNames?: string[];
  fromHome?: boolean;
  type: ExerciseType;
  isDraft: boolean;
  picture?: MediaFile | File;
  images?: (MediaFile | File)[];
  videos?: (MediaFile | File)[];
};

export type Exercise = ItemBase & CreationExercise;
