import { useItemsSelection } from "../../contexts/ItemsSelectionContext";
import { isAdmin } from "../../utils/isAdmin";
import GradientButton from "../GradientButton/GradientButton";
import NewButton from "../NewButton/NewButton";
import SearchBar from "../SearchBar/SearchBar";

export type TopFiltersProps = {
    onCreate: () => void;
    onSearch: (data: string) => void;
    onStartDeletion: () => void;
    createLabel?: string;
};

const TopFilters = ({ onCreate, onSearch, onStartDeletion, createLabel = 'Crear' }: TopFiltersProps) => {
    const { selected } = useItemsSelection();
    
    return (
        <div className="top-filters">
            <NewButton click={onCreate}>{createLabel}</NewButton>
            <SearchBar passData={onSearch} />
            {isAdmin() && (
                <GradientButton color='red' click={onStartDeletion} disabled={!selected?.length}>
                    Borrar selección
                </GradientButton>
            )}
        </div>
    )
};

export default TopFilters