import React, { useState, useEffect } from 'react';
import './InputText.scss';

type InputTextProps = {
    value?: string;
    onChange: (value: string) => void;
    placeholder?: string;
    textarea?: boolean;
    password?: boolean;
    disabled?: boolean;
    dark?: boolean;
};

const InputText = ({ value, onChange, placeholder, textarea, password, disabled, dark }: InputTextProps) => {
    if (textarea) return (
        <textarea
            className={`InputText ${dark ? 'dark' : ''}`}
            value={value}
            rows={1}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder || 'Escribe aquí'}
        />
    );
    return (
        <input
            className={`InputText ${dark ? 'dark' : ''}`}
            type={password ? "password" : "text"}
            value={value}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder || 'Escribe aquí'}
        />
    );
};

export default InputText;