import type { Exercise } from "../models/v2/exercise";
import type { Resource } from "../models/v2/resources";
import type { Fields } from "../utils/tables/fields";
import { genericFields } from "./generic";

const fields: Fields<Exercise> = {
  ...genericFields,
  nameES: {
    label: "Nombre (ES)",
  },
  nameEN: {
    label: "Nombre (EN)",
  },
  bodyArea: {
    label: "Zona del cuerpo",
  },
  type: {
    label: "Tipo",
  },
  isDraft: {
    label: "Borrador",
    getValue: ({ isDraft }) => isDraft ? "Sí" : "No",
  },
  picture: {
    label: "Foto",
    getValue: ({ picture }) => {
      if (!picture) {
        return "-";
      }

      if (!(picture instanceof File) && picture.publicUrl) {
        return {
          type: "image",
          url: picture.publicUrl,
        }
      }
    },
  }
};

const headers = ["picture", "nameES", "nameEN", "bodyArea", "type", "isDraft"];

const exercisesData: Resource<Exercise> = {
  fields,
  headers,
};

export default exercisesData;
